<template>
    <div>
      <Page :tabList="tabList"></Page>
    </div>
  </template>
  
  <script>
  import Page from "../../components/public/Page.vue";
  export default {
    components: {
      Page,
    },
    data() {
      return {
        tabIndex: 0,
        tabList: [
          {
            name: "现场管理检查",
            path: "/site-page",
          },
          {
            name: "安全检查",
            path: "/rules-page",
          },
          {
            name: "例行检查",
            path: "",
          },
        ],
      };
    },
    mounted() {},
    methods: {},
  };
  </script>
  
  <style lang="less" scoped >
  </style>